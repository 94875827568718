import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import Header from "../components/header/header";
import LoginSocials from "../components/login_socials/login_socials";

function LoginSocialsPage() {
  return (
    <ChakraProvider>
      <Header />
      <LoginSocials />
    </ChakraProvider>
  );
}

export default LoginSocialsPage;
