import { Box, Center, Divider, HStack } from "@chakra-ui/react";
import React from "react";
import {
  OpaliteButtonLoginWithSocial,
  OpaliteButtonStandard,
} from "../shared/opalite_buttons";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import { OpaliteTextStandard } from "../shared/opalite_text";

interface LoginSocialsProps {}

const LoginSocials: React.FC<LoginSocialsProps> = (props) => {
  return (
    <Center
      height="100vh"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignContent="center"
      backgroundColor={COLORS_PRIMARY.BACKGROUND}
    >
      <Box
        borderColor={COLORS_PRIMARY.FONT}
        borderRadius="10px"
        borderWidth="2px"
        padding="2rem"
        display="flex"
        flexDirection="column"
        width="80%"
      >
        <OpaliteButtonStandard marginBottom=".5rem">
          Log in
        </OpaliteButtonStandard>
        <Center
          marginBottom=".5rem"
          display="flex"
          textAlign="center"
          paddingInline="2rem"
          flexDirection="row"
          justifyItems="center"
        >
          <Divider
            backgroundColor={COLORS_PRIMARY.FONT}
            borderWidth="1px"
            color={COLORS_PRIMARY.FONT}
            width="50%"
          />
          <OpaliteTextStandard
            marginInline=".5rem"
            display="flex"
            textAlign="center"
            justifySelf="center"
          >
            or
          </OpaliteTextStandard>
          <Divider
            backgroundColor={COLORS_PRIMARY.FONT}
            borderWidth="1px"
            color={COLORS_PRIMARY.FONT}
            width="50%"
          />
        </Center>

        <OpaliteButtonStandard>Sign up</OpaliteButtonStandard>
      </Box>
      <Box marginTop="2rem" width="60%">
        <OpaliteButtonLoginWithSocial
          marginBottom="1rem "
          iconType="instagram"
          width="100%"
        >
          Instagram
        </OpaliteButtonLoginWithSocial>
        <OpaliteButtonLoginWithSocial iconType="tik tok" width="100%">
          Tik Tok
        </OpaliteButtonLoginWithSocial>
      </Box>
    </Center>
  );
};

export default LoginSocials;
